import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { useIntl } from "gatsby-plugin-react-intl"
import { device } from "../styles/device"
import { ParallaxProvider } from "react-scroll-parallax"
import { Parallax } from "react-scroll-parallax"

const ContactUsSection = () => {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        query {
            allContentfulContactPage {
                nodes {
                    contactSectionBackground {
                        gatsbyImageData(quality: 50)
                    }
                    headline
                }
            }
        }
    `)
    const content =
        intl.locale === "ja" ? data.allContentfulContactPage.nodes[0] : data.allContentfulContactPage.nodes[1]

    return (
        <ParallaxProvider>
            <div css={wrapper}>
                <Link to="/contact/">
                    <div css={parallaxContainer}>
                        <Parallax y={[-40, 0]} tagOuter="div">
                            <GatsbyImage
                                image={getImage(content.contactSectionBackground)}
                                key="contact background"
                                alt="contact background"
                                css={backgroundImg}
                            />
                        </Parallax>
                    </div>
                    <div css={textBox}>
                        <h6 css={contactLink}>Contact</h6>
                        <p css={contactHeadline}>{content.headline}</p>
                    </div>
                    <div css={chevron}></div>
                </Link>
            </div>
        </ParallaxProvider>
    )
}
export default ContactUsSection

const wrapper = css`
    width: 100%;
    height: 35vh;
    position: relative;
    background: black;
    margin: 0 auto 20vh;

    @media ${device.sp} {
        height: 20vmax;
    }
`
const parallaxContainer = css`
    width: 100%;
    height: 100%;
    overflow: hidden;
`
const backgroundImg = css`
    z-index: 0;
    opacity: 0.5;
    transition: 1s cubic-bezier(0.6, -0.05, 0.01, 0.9);
    &:hover {
        opacity: 0.4;
    }
`
const textBox = css`
    text-align: center;
    color: white;
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media ${device.sp} {
        h6 {
            font-size: 1.4rem;
        }
        p {
            border-bottom: solid 1px white;
            font-size: 1.25rem;
            display: inline;
        }
    }
`
const contactHeadline = css`
    margin-top: 0.8rem;
`
const contactLink = css`
    color: white;
    /* border: solid 1px white; */
    font-size: 2.4rem;
    letter-spacing: 0.07rem;
    &:hover {
        /* color: black; */
        /* border: solid 1px black; */
        /* background: white; */
    }
`
const chevron = css`
    width: 30px;
    height: 30px;
    border-top: solid 1px white;
    border-right: solid 1px white;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    top: 50%;
    right: 3%;
    @media ${device.sp} {
        display: none;
    }
`
